import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { withBrand } from '../../../providers/BrandProvider';

import './ModalPromptMA.scss';

export class ModalPromptMA extends Component {
  static propTypes = {
    brand: PropTypes.object,
    content: PropTypes.object,
    getMALocation: PropTypes.func,
    sendMASegment: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedSection: null,
    };
  }

  getRadioMode(prompts) {
    return (
      <React.Fragment>
        {prompts.map((item, index) => {
          return (
            <div className="radio-custom-wrapper" key={index}>
              <label
                htmlFor={item.id}
                onClick={() => this.setState({ selectedSection: item.name })}
              >
                <input
                  id={item.id}
                  name="ma-prompt-radio"
                  value={item.name}
                  type="radio"
                  className="radio"
                />
                <span className="radio-custom" />
                <span className="radio-prompt">{item.textForVisitor}</span>
              </label>
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  getTextButtons(prompts) {
    return (
      <React.Fragment>
        {prompts.map((item, index) => {
          return (
            <span
              key={index}
              className="button apply-prompt"
              onClick={() => this.props.sendMASegment(item.name)}
            >
              {item.textForVisitor}
            </span>
          );
        })}
      </React.Fragment>
    );
  }

  render() {
    const {
      content: { header, introText, closeOutText, mode, prompts } = {},
      brand: { secondaryColor } = {},
      t,
    } = this.props;
    const popupStyle = { backgroundColor: secondaryColor };
    const isRadioMode = mode === 'RADIO_BUTTONS';
    const content = isRadioMode
      ? this.getRadioMode(prompts)
      : this.getTextButtons(prompts);
    const { selectedSection } = this.state;
    const selectedSectionParams = selectedSection
      ? this.props.getMALocation(selectedSection)
      : null;

    return (
      <div
        className={`modal-ma ${isRadioMode ? 'radio' : ''}`}
        style={popupStyle}
      >
        <div className="header-text">{header}</div>
        <div className="intro-text">{introText}</div>
        <div className={`content ${isRadioMode ? 'radio' : 'text'}`}>
          {content}
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-6">
            {isRadioMode && (
              <div
                className={`button submit-prompt ${
                  selectedSectionParams ? '' : 'inactive'
                }`}
                onClick={() => this.props.sendMASegment(selectedSection)}
              >
                {t('event.submit')}
              </div>
            )}
          </div>
          <div
            className={`col-xs-12 col-sm-6 ${
              !isRadioMode ? ' col-sm-offset-6' : ''
            }`}
          >
            <div className="choice-close">
              <span
                onClick={() => this.props.sendMASegment('none')}
                className="popup-close-btn"
              >
                <span>{closeOutText}</span>
                <span className="popup-close">×</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(withBrand(ModalPromptMA)));
